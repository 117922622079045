import { ComponentType } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  Picture,
  CardHcd,
} from '@maiia/model/generated/model/api-patient/api-patient';
import { HttpError, actionsGenerator, asyncActions } from '@docavenue/core';
import { AxiosError } from 'axios';

export const getDisplayName = (WrappedComponent: ComponentType<any>) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

/**
 * Copy from: @wessberg/stringutil@1.0.19
 * kebab-cases (dash-cases) the given string.
 */
export function kebabCase(str: string) {
  // Lower cases the string
  let _str = str;
  if (!/[a-zæøåàáäâëêéèïîíìöòóôüúùû]/.test(_str)) _str = str.toLowerCase();
  return _str
    .replace(
      /(?:_)[A-ZÅÀÁÂÄÆËÊÉÈÏÎÍÌÖÔÒÓØÜÛÚÙ]{2,}|[A-Z]{2,}(?=_)/g,
      $1 => ` ${$1.toLowerCase()}`,
    )
    .replace(/[-_+]/g, ' ')
    .replace(
      /[ \t\r]*[A-ZÅÀÁÂÄÆËÊÉÈÏÎÍÌÖÔÒÓØÜÛÚÙ]+[ \t\r]+/g,
      $1 => ` ${$1.toLowerCase()} `,
    )
    .replace(/[A-ZÅÀÁÂÄÆËÊÉÈÏÎÍÌÖÔÒÓØÜÛÚÙ]/g, $1 => ` ${$1.toLowerCase()}`)
    .replace(/^[ \t\r]+/g, '')
    .replace(/\s{2,}/g, ' ')
    .replace(/\s+/g, '-');
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Debounce, throttle utils
 */
export function debounce(fn, delay = 50) {
  let timerId;
  return function debounceCallback(...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

// throttle improve with lastArgs
export function throttleWithLastArgs(fn, threshold = 250) {
  let lastArgs;
  let deferTimer;
  const next = () => {
    deferTimer = clearTimeout(deferTimer); // clearTimeout and set deferTimer to undefined
    fn(...lastArgs);
  };
  return function throttleWithLastArgsCallback(...args) {
    lastArgs = args;
    if (deferTimer === undefined) {
      deferTimer = setTimeout(next, threshold);
    }
  };
}

export const formatErrorCode = (action, code) =>
  `${action?.resource}_${action?.type}_${code}`.toLowerCase();

/**
 * Pattern for the keys
 * {prefix}{resource}_{action}_{subCode}{suffix}
 * {prefix}{subCode}{suffix}
 * {prefix}{resource}_{action}_{code}{suffix}
 * {prefix}{code}{suffix}
 * {defaultKey}
 *
 * Keys are generated 2 times in the following order
 * 1. Lowercase
 * 2. Normal (unchanged)
 * 3. defaultKey
 */
export const buildTranslationKeysByCodes = ({
  defaultKey = undefined,
  prefix = '',
  suffix = '',
  resource = undefined,
  action = undefined,
  subCodes = [],
  code = 'UNKNOWN_CODE',
}: {
  defaultKey?: string;
  prefix?: string;
  suffix?: string;
  resource?: string;
  action?: string;
  subCodes?: string[];
  code?: string;
}) => {
  const resourceString = resource ? `${resource}_`.toLocaleLowerCase() : '';
  const actionString = action ? `${action}_`.toLocaleLowerCase() : '';
  const keys: string[] = [];
  if (subCodes) {
    for (const subCode of subCodes) {
      keys.push(`${prefix}${resourceString}${actionString}${subCode}${suffix}`);
      keys.push(`${prefix}${subCode}${suffix}`);
    }
  }
  keys.push(`${prefix}${resourceString}${actionString}${code}${suffix}`);
  keys.push(`${prefix}${code}${suffix}`);

  const result = [...keys.map(k => k.toLowerCase()), ...keys];
  if (defaultKey) result.push(defaultKey);
  return result;
};

// For backward compatibility, error could be set on code or subCode, then the translation keys already exist could be in any form.
type Error = {
  action: {
    resource: string;
    type: string;
  };
  code?: string;
  subCodes: string[];
  message?: string;
};

export const buildErrorTranslationKeys = (
  error: Error,
  {
    defaultKey = undefined,
    prefix = '',
    suffix = '',
  }: { defaultKey?: string; prefix?: string; suffix?: string },
) => {
  const { action } = error;
  return buildTranslationKeysByCodes({
    defaultKey,
    prefix,
    suffix,
    resource: action.resource,
    action: action.type,
    subCodes: error.subCodes,
    code: error.code,
  });
};

export const buildAnyErrorTranslationKeys = (
  errorObj: any,
  {
    defaultKey = undefined,
    prefix = '',
    suffix = '',
    resource = undefined,
    action = undefined,
  }: {
    defaultKey?: string;
    prefix?: string;
    suffix?: string;
    resource?: string;
    action?: string;
  },
) => {
  let error = errorObj;
  if (error?.error instanceof HttpError) {
    // legacy / redux
    error = error?.error;
  } else if (error instanceof AxiosError) {
    // react-query / axios
    error = error.response?.data;
  }

  return buildTranslationKeysByCodes({
    defaultKey,
    prefix,
    suffix,
    resource,
    action,
    subCodes: error.subCodes,
    code: error.code,
  });
};
export const isPharmagest = domain => domain && domain.includes('pharmagest');

export const couldBeAPharmacy = subdomain =>
  subdomain && !['www', 'localhost', 'pat', 'partners'].includes(subdomain);

export const dayjsWithTimezone = (timezone: string) => (
  date?: string | number | Dayjs,
) => dayjs.tz(date, timezone);

export const isOnlineTlc = hcdCard => {
  const timezone = hcdCard?.settings?.timeZone;
  const dayjsTZ = dayjsWithTimezone(timezone);
  return dayjsTZ().isBetween(
    dayjsWithTimezone(timezone)(
      hcdCard?.settings?.teleconsultationAvailabilityStartTime,
    ),
    dayjsWithTimezone(timezone)(
      hcdCard?.settings?.teleconsultationAvailabilityEndTime,
    ),
  );
};

export const getFavoritePractionerIds = (profiles: Array<any>) =>
  Array.from(
    new Set(
      profiles
        .map(profile =>
          (profile?.favoritePractitioners ?? []).map(
            practitioner => practitioner.practitionerId,
          ),
        )
        .reduce((result, list) => result.concat(list), []),
    ),
  );

export const getReferringPractitionerIds = (profiles: Array<any>) =>
  Array.from(
    new Set(
      profiles.map(
        profile => profile?.referringPractitioner?.practitionerId ?? '',
      ),
    ),
  ).filter(x => x !== '');

export const upperCaseFirstLetter = string =>
  string
    .toLowerCase()
    .charAt(0)
    .toUpperCase() + string.slice(1);

export const pictures = (practitionerCard: CardHcd) => {
  let picturesArr: Picture[] = [];
  const mainPicture = practitionerCard?.publicInformation?.mainPicture;
  const pratictionerPictures =
    practitionerCard?.publicInformation?.pictures ?? [];

  if (pratictionerPictures.length) picturesArr = [...pratictionerPictures];
  if (mainPicture) picturesArr = [mainPicture, ...picturesArr];

  return picturesArr;
};

export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const ret: any = {};
  keys.forEach(key => {
    ret[key] = obj[key];
  });
  return ret;
}

export const isLocalstorageAllowed = () => {
  try {
    localStorage.setItem('test-ls-enabled', 'test');
    localStorage.removeItem('test-ls-enabled');
    return true;
  } catch (err) {
    return false;
  }
};

// Payment card utils
export const formatCardExpiryDate = ({ expirationDate }) => {
  const [yyyy, mm] = expirationDate.split('-');
  return `${mm}/${yyyy}`;
};

export const isCardExpired = ({ expirationDate }) => {
  const dayInMs = 86400000;
  // The card will be considered expired ONE day earlier to avoid issues with timezones
  const expirationDay = Date.parse(expirationDate) - dayInMs;

  return Date.now() > expirationDay;
};

// only work in browser
export const safeRedirectURL = (
  url: string | undefined,
): string | undefined => {
  if (typeof window === 'undefined') {
    throw new Error('Unsupported');
  }
  if (!url) return;
  const a = document.createElement('a');
  a.href = url;
  if (a.hostname !== window.location.hostname) return;
  return url;
};

export const getDocumentBlobUrl = async (dispatch, id, isThumbnail = false) => {
  const documentActions = actionsGenerator({
    resource: 'document-contents',
  });
  const data = await asyncActions(
    dispatch,
    documentActions.getOne(id, {
      isThumbnail,
    }),
  );
  const objectURL = URL.createObjectURL(data);

  return objectURL;
};

export const getInstructionDocumentBlobUrl = async (
  dispatch,
  instructionId,
  documentId,
) => {
  const documentActions = actionsGenerator({
    resource: 'instructionDocumentContents',
    chunkUrlResource: `instructions/${instructionId}/documents/${documentId}/content`,
    scope: 'pat-public',
  });
  const data = await asyncActions(
    dispatch,
    documentActions.getOneWithCompositeKey({}),
  );
  const objectURL = URL.createObjectURL(data);

  return objectURL;
};

export const fetchClient = (endpoint: string, customConfig = {}) => {
  const config = {
    method: 'GET',
    query: {},
    ...customConfig,
  };
  return fetch(`${endpoint}?${new URLSearchParams(config.query)}`, config).then(
    async response => {
      if (response.status === 204) return null;
      const data = await response.json();
      if (response.ok) {
        return data;
      }
      return Promise.reject(data);
    },
  );
};

export const fetchClientWithTimeout = async (
  endpoint: string,
  customConfig = {},
  timeout = 5000,
) => {
  const controller = new AbortController();
  const { signal } = controller;

  const timeoutId = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    const result = await fetchClient(endpoint, { signal, ...customConfig });
    return result;
  } finally {
    clearTimeout(timeoutId);
  }
};

export const readingTime = article => {
  // 250 word/min is the average reading speed
  const numberValue = article.content?.rendered?.split(' ')?.length / 250;
  return numberValue < 1 ? '> 1 min' : `${Math.ceil(numberValue)} min`;
};

export const isNullOrEmptyString = (text: string | null | undefined) => {
  return !text || text.length === 0;
};

// https://stackoverflow.com/a/39914235
export const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));

export const isPositiveNumber = (value?: number) => {
  return !!value && value > 0;
};

// expected the email is valided
export const maskEmail = (email: string) => {
  const [username, domain] = email.split('@');
  let maskedUsername;

  if (username.length <= 3) {
    maskedUsername = username[0] + '•'.repeat(3);
  } else {
    maskedUsername =
      username.substring(0, 3) + '•'.repeat(Math.max(username.length - 3, 3));
  }

  return `${maskedUsername}@${domain}`;
};
